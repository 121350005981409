export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="./img/a1_logo_alt.png" alt="A1 Logo" />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#tjanster" className="page-scroll">
                Tjänster
              </a>
            </li>
            <li>
              <a href="#referensprojekt" className="page-scroll">
                Projekt
              </a>
            </li>
            {/* <li>
              <a href="#karriar" className="page-scroll">
                Karriär
              </a>
            </li> */}
            {/* <li>
              <a href="#teamet" className="page-scroll">
                Teamet
              </a>
            </li> */}{" "}
            <li>
              <a href="#omoss" className="page-scroll">
                Om oss
              </a>
            </li>
            <li>
              <a href="#kontakt" className="page-scroll">
                Kontakt
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
