import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from "./image";
export const SimpSlider = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,

    centerPadding: "60px",
  };

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 2000,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  console.log(props.data);
  return (
    <div id="referensprojekt" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Referensprojekt</h2>
          <p>Kolla in några av projekten vi varit involverade i</p>
        </div>
        <div className="portfolio-items">
          <Slider {...settings2}>
            {props.data ? (
              props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="no-margins-at-all">
                  <div className="project-desc">
                    {" "}
                    <img
                      src={d.smallImage}
                      className="img-responsive"
                      alt={d.title}
                    />{" "}
                    <h4>{d.title}</h4>
                    <p>{d.description}</p>
                    <p>
                      <strong>Byggår:</strong> {d.buildingYear}
                    </p>
                    <p>
                      <strong>Budget:</strong> {d.budget}
                    </p>
                    <p>
                      <strong>Beställare:</strong> {d.owner}
                    </p>{" "}
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};
