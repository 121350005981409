export const About = (props) => {
  return (
    <div id="omoss">
      <div className="container">
        <div className="row">
          {/* <div className="col-xs-12 col-md-">
            {" "}
            <img
              src="img/OM_A1.jpeg"
              className="img-responsive-about"
              alt=""
            />{" "} 
          </div> */}
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Om A1</h2>
              <p>
                {props.data
                  ? props.data.paragraph
                  : "loading..."}
              </p>
              {/* <h3>Varför ska du välja oss?</h3>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li style={{ marginBottom: "5px" }} key={`${d}-${i}`}>
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
